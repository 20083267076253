<template>
  <div ref="mapWrapper" class="map-wrapper">
    <div id="view" ref="view">
      <div id="map" ref="mapImage" class="map-base map-25vh">
        <inline-svg
          :src="require(`../../assets/images/gallery1/map-0.svg`)"
          @loaded="svgLoaded($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { gsap, Power2 } from "gsap";

import panzoom from "panzoom";

export default {
  data() {
    return {
      viewInstance: null,
      mouse: { x: 0, y: 0 },
      mouseOrigin: { x: 0.5, y: 0.5 },
    };
  },
  mounted() {
    this.$store.commit("updateCurrentMap", 0);

    const vm = this;
    this.viewInstance = panzoom(this.$refs.view, {
      zoomSpeed: 0.05,
      transformOrigin: { x: 0.25, y: 0.5 },
      minZoom: 0.8,
      maxZoom: 4,
    });
  },
  methods: {
    svgLoaded() {
      const tl = new gsap.timeline();
      tl.set(this.$refs.mapImage, {
        scale: 2,
        x: "-28vw",
        y: "50vh",
        opacity: 0,
      });
      tl.to(this.$refs.mapImage, {
        duration: 3,
        y: "25vh",
        opacity: 1,
        ease: Power2.easeInOut,
        onComplete: () => {
          if (this.$route.name == "Map-0") {
            this.$store.commit("updateMapDialog", true);
          }
        },
      });
    },
    mouseOver() {},
  },
};
</script>

<style>
</style>
